import React from "react";

import Layout from "components/Layout";
import Container from "components/Container";
import Intro from "components/Intro";
import { ArrowSmallEx } from "components/Arrow";
import { SEO } from "components/SEO";

const ContactPage = () => {
	return (
		<Layout pageName="contact">
			<SEO
				title="Blue Medical Contact"
				description="Phone number, e-mail address and postal address of Blue Medical Switzerland."
			/>

			<Intro className="contact-intro">
				<h1 className="mono">Contact us</h1>
			</Intro>

			<Container sectionName="contact">
				<div className="contact__contact-wrapper">
					<div className="contact__phone-mail">
						{/* <span className="mono">Contact us</span> */}
						<p className="copy-large">
							Phone +41 34 421 81 11 <br />
							welcome@bluemedical.swiss
						</p>
					</div>

					<div className="contact__address">
						<div className="address-hq">
							<address className="copy-large">
								Blue Medical AG
								<br />
								Kirchbergstrasse 160
								<br />
								3400 Burgdorf
								<br />
								Switzerland
							</address>
							<div className="link-wrapper">
								<ArrowSmallEx />
								<a
									href="https://goo.gl/maps/eMXW3G2kmYTq3o9eA"
									target="_blank"
									rel="noopener noreferrer"
								>
									Google Maps
								</a>
							</div>
						</div>
					</div>
				</div>
			</Container>
		</Layout>
	);
};

export default ContactPage;
